//navbar

.navbar{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all .3s ease-in-out;
}

.scrolled{
  .navbar{
    position: fixed;
    padding: .5rem 1rem;
    background:$primary;
    box-shadow: 0 20px 50px 0 rgba(0,0,0,.05);
    transition: all .3s ease-in-out;
  }
}

//Menu responsive
.navbar-toggler{
  border: none;
  border-radius: 0;
  &:hover, &:focus{
    outline: 0;
  }
  .toggler-icon{
    display: block;
    position: relative;
    width: 30px;
    height: 2px;
    margin: 5px 0;
    background-color: $white;
    transition: all .3s ease-out 0s;
  }
}

.navbar-toggler{
  &[aria-expanded="true"]{
    .toggler-icon{
      &:nth-of-type(1){
        transform: rotate(45deg);
        top: 7px;
      }

      &:nth-of-type(2){
        opacity: 0;
      }

      &:nth-of-type(3){
        transform: rotate(135deg);
        top: -7px;
      }
    }
  }
}

.navbar-collapse{
  background-color: $primary;
  padding: .8rem;
}


//Responsive
@include media-breakpoint-up(lg) {

  .nav-link{
    position: relative;
    &:before{
      content: "";
      background: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      opacity: 0;
      transition: all .3s ease-out;
      transform-origin: left;
      transform: scaleX(0);
    }

    &:hover, &.active{
      &:before{
        width: 40%;
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }

  .nav-item{
    .nav-link{
      &:before{
        margin-left: .8rem;
      }
    }
  }

  .navbar-collapse{
    background-color: transparent;
    padding: .8rem;
  }
}
