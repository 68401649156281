//Appel de fonts
@include font-face('poppins', '../fonts/poppins-light', 300, normal);
@include font-face('poppins', '../fonts/poppins-regular', 400, normal);
@include font-face('poppins', '../fonts/poppins-bold', 700, normal);

html, body{
  width: 100%;
  height: 100%;
}

body{
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.main-content{
  flex: 1 0 auto;
}

section{
  position: relative;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-weight: 700;
}

.stronger{
  font-weight: 700;
}

.text-pink{
  color: $primary;
}

.img-styled{
  border-radius: 1em;
  box-shadow: $box-shadow-lg;
}

//Titre section
.section__title{
  .title{
    position: relative;
    padding-bottom: .5rem;
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left:0;
      background: $section-title-bg;
      height: 2px;
      width: 80px;
    }
  }

  &.text-left{
    .title{
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left:0;
      }
    }
  }

  &.text-center{
    .title{
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left:50%;
        transform: translateX(-50%);
      }
    }
  }

  &.text-right{
    .title{
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        right:0;
      }
    }
  }
}

.hidden{
  display: none;
}