// home

.home{
  position: relative;
  background-image: url('../img/header-bg.svg');
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  //height: 100vh;
  &__container{
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
}

//Responsive
@include media-breakpoint-up(md) {
  .home{

    &__content{
      font-size: 1.25rem;
    }

    &__title{
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }
  }
}
