.footer{
  position: relative;
  flex-shrink: 0;
  padding-top: 120px;
  font-size: $font-size-sm;
  &:before{
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/footer-bg.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }

  &__top, &__bottom{
    color: $white;
  }

  &__top{
    margin: 50px 0;
  }

  &__bottom{
    border-top: 1px solid rgba(#fff, .3);
  }
}

.footer{
  &__list{
    text-align: center;
  }

  &__title{
    font-weight: 700;
  }

  &__nav{
    text-align: center;
  }

  &__logo{
    img{
      display: block;
      margin: 0 auto;
    }
  }

}

.footer__bottom{
  .copyright, .legal{
    text-align: center;
  }
}

//Responsive
@include media-breakpoint-up(lg) {

  .footer{
    &__list{
      text-align: right;
    }

    &__nav{
      text-align: left;
    }

    &__logo{
      display: inline-block;
    }
  }

  .footer__bottom{
    .copyright{
      text-align: left;
    }

    .legal{
      text-align: right;
    }
  }

  .footer__nav{
    .nav-link{
      &:before{
        margin-left: .5rem;
      }
    }
  }
}