//Retour haut de Page

.back-to-top{
  display: none;
  position: fixed;
  bottom: 46px;
  right: 32px;
  z-index: $zindex-fixed;

  &__link{
    display: block;
    text-align: center;
    width: $back-to-top-size;
    height: $back-to-top-size;
    line-height: $back-to-top-size;
    background: $back-to-top-bg;
    color: $back-to-top-color;
    border-radius: $back-to-top-radius;
    box-shadow: $box-shadow-lg;
    @include hover() {
      color: $back-to-top-hover-color;
    }
  }
}
