  /* Cookie */
  .mca_cookiecontain{
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    z-index: 1100;
  }

  .mca_cookieblock{
    display: block;
    padding: 15px;
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  //Responsive
    @include media-breakpoint-up(lg) {
    .mca_cookiecontain{
      left: 20px;
      transform: none;
    }
  }
