//Services
.services{
    &__heading{
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background: rgba($primary, 0.3);
        color: $primary;
        font-weight: 700;
        font-size: 1.5rem;
    }
}