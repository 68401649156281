// call to action

.cta{
  background: #fff;
  border-radius: 1em;
  box-shadow: $box-shadow-lg;
  padding:2rem;

  &-gradient{
    background: rgb(229,8,93);
    background: linear-gradient(135deg, rgba(229,8,93,1) 0%, rgba(109,58,109,1) 100%);
    color: #fff;
  }
}
